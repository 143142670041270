import React, { Fragment, useEffect, useState } from "react"
import { isBrowser } from "../../context/ApolloContext"
import { useForm } from "react-hook-form"
import {
  injectIntl,
  FormattedMessage,
  FormattedNumber,
} from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import Loader from "react-loader-spinner"
import styled from "@emotion/styled"
const $ = isBrowser ? require("jquery") : {}

import { withStoreContext } from "../../context/StoreContext"
import {
  NewsletterSubscribe,
  NewsletterSubscribeWithSMS,
} from "../../utils/NewsletterSubscriptionHelper"
import { formSubmitEventScript } from "../../utils/additionalScriptHelpers"

const NewsLetterMultipleStepPopup = ({
  storeContext: { checkout, addDiscount },
  intl,
}) => {
  const { register, handleSubmit, errors, reset } = useForm()
  const [NewsTrans, setNewsTrans] = useState({})
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState(null)
  const [success, setsuccess] = useState(null)
  const [userEmail, setuserEmail] = useState("")
  const [firstStepComplete, setfirstStepComplete] = useState(false)
  const [secondStepComplete, setsecondStepComplete] = useState(false)
  const [IsMobile, setIsMobile] = useState(false)
  const [showGiftIcon, setshowGiftIcon] = useState(true)
  const [infoMessage, setInfoMessage] = useState(null)

  const discountCode =
    process.env.GATSBY_ACTIVE_ENV === "gbp"
      ? "WELCOME5"
      : process.env.GATSBY_ACTIVE_ENV === "eur"
      ? "FLASH"
      : ""

  const discountAmount = process.env.GATSBY_ACTIVE_ENV === "gbp" ? 5 : 10

  let emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
  let dobRe = new RegExp(
    /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/
  )
  const handleTimeOut = () => {
    setTimeout(() => {
      if (isBrowser && !IsMobile) {
        let counter = localStorage.getItem("newsLetterPopupCloseCounter")
        if (counter < 2) {
          let session = localStorage.getItem("newsLetterWeekendSpaPopup")
          if (session != "true") {
            $(".newsletter_weekend_spa_popup").show()
          }
        }
      }
    }, 60000)
  }
  const popupClose = () => {
    let count = 0
    setfirstStepComplete(false)
    setsecondStepComplete(false)
    seterror(null)
    setsuccess(null)
    document.getElementById("newsletterPopupForm").reset()
    let counter = localStorage.getItem("newsLetterPopupCloseCounter")
    if (!counter) {
      counter = count
    }
    if (counter < 2) {
      localStorage.setItem("newsLetterPopupCloseCounter", counter + 1)
    }
    $(".newsletter_weekend_spa_popup").hide()
  }
  const data = useStaticQuery(graphql`
    query NewsLetterMultipleStepQuery {
      allDirectusNewsletterTranslation {
        nodes {
          email_error_text
          require_error_text
          thanks_text
          email_exist_text
          invalid_email_text
          invalid_dob_text
          language
        }
      }
    }
  `)
  function Translation() {
    if (data && data.allDirectusNewsletterTranslation.nodes.length > 0) {
      let node
      data.allDirectusNewsletterTranslation.nodes.forEach((item, index) => {
        if (item.language === intl.locale) {
          node = index
        }
      })
      if (node >= 0) {
        setNewsTrans(data.allDirectusNewsletterTranslation.nodes[node])
      }
    }
  }

  const onFocus = event => {
    event.target.classList.add("focused")
  }

  const onBlur = event => {
    if (event.target.value == "") {
      event.target.classList.remove("focused")
    }
  }

  const moveSecondStep = async () => {
    setsuccess(null)
    if (emailRegex.test(userEmail)) {
      setloading(true)
      let firstStepProcess = await NewsletterSubscribeWithSMS({
        subscribeEmail: userEmail,
      })
      if (firstStepProcess.success) {
        applyWelcomeDiscount()
      } else {
        console.log("error", firstStepProcess)
        switch (firstStepProcess.error) {
          case "invalid_email":
            console.log("invalid_email")
            break
          case "internal_error":
            console.log("internal_error")
            break
          case "no_email_provided":
            console.log("no_email_provided")
            break
        }
      }
      setloading(false)
      setfirstStepComplete(true)
      setsecondStepComplete(false)
    } else {
      seterror(
        intl.formatMessage({
          id: "newsletter_subscribe_invalid_email_error",
        })
      )
    }
  }

  const onSubmit = data => {
    // const { email, dob } = data
    const { email, telephone } = data
    var testGroupArr = ["A", "B"]
    var testGroupVal =
      testGroupArr[Math.floor(Math.random() * testGroupArr.length)]
    seterror(null)
    if (email) {
      if (Object.keys(errors).length === 0) {
        setloading(true)

        NewsletterSubscribeWithSMS({
          subscribeEmail: email,
          subscribePhone: telephone,
        })
          .then(response => {
            console.log("NewsletterSubmit", response)
            if (response.success) {
              formSubmitEventScript("newsletter_submit", intl.locale)
              seterror(null)
              setsuccess(NewsTrans.thanks_text)
              localStorage.setItem("newsLetterWeekendSpaPopup", true)
              setshowGiftIcon(false)
              setfirstStepComplete(true)
              setsecondStepComplete(true)
              applyWelcomeDiscount()
            } else {
              if (response.msg.indexOf(email) == 0) {
                seterror(NewsTrans.email_exist_text.replace("xxx@xxx", email))
              } else {
                seterror(response.msg)
              }
              setfirstStepComplete(false)
              setsuccess(null)
              reset()
            }
            setloading(false)
          })
          .catch(response => {
            console.log("error", response)
            switch (response.error) {
              case "invalid_email":
                console.log("invalid_email")
                seterror(
                  intl.formatMessage({
                    id: "newsletter_subscribe_invalid_email_error",
                  })
                )
                setsuccess(null)
                break
              case "internal_error":
                seterror(
                  intl.formatMessage({
                    id: "newsletter_subscribe_internal_error",
                  })
                )
                setsuccess(null)
                break
              case "no_email_provided":
                seterror(
                  intl.formatMessage({
                    id: "newsletter_subscribe_no_email_provided_error",
                  })
                )
                setsuccess(null)
                break
            }
            setloading(false)
          })
      }
    }
  }

  const checkValue = (str, max) => {
    if (str.charAt(0) !== "0" || str == "00") {
      var num = parseInt(str)
      if (isNaN(num) || num <= 0 || num > max) num = 1
      str =
        num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
          ? "0" + num
          : num.toString()
    }
    return str
  }

  const checkUserEmail = e => {
    var input = e.target.value
    setuserEmail(input)
  }

  const checkAndFormatDob = e => {
    e.target.type = "text"
    var input = e.target.value
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3)
    var values = input.split("/").map(v => {
      return v.replace(/\D/g, "")
    })
    if (values[1]) values[1] = checkValue(values[1], 12)
    if (values[0]) values[0] = checkValue(values[0], 31)
    var output = values.map((v, i) => {
      return v.length == 2 && i < 2 ? v + "/" : v
    })
    e.target.value = output.join("").substr(0, 10)
  }

  const windowResize = () => {
    var w = window.outerWidth
    if (w <= 600) {
      setIsMobile(true)
    }
    $(window).resize(() => {
      var w = window.outerWidth
      if (w <= 600) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
  }

  const ShowPopupOnScroll = () => {
    var mouseX = 0
    var mouseY = 0
    var popupCounter = 0

    if (!IsMobile) {
      document.addEventListener("mousemove", function(e) {
        mouseX = e.clientX
        mouseY = e.clientY
      })

      $(document).mouseleave(function() {
        if (mouseY < 100) {
          if (popupCounter < 1) {
            let counter = localStorage.getItem("newsLetterPopupCloseCounter")
            if (counter < 1) {
              let session = localStorage.getItem("newsLetterWeekendSpaPopup")
              if (session != "true") {
                $(".newsletter_weekend_spa_popup").show()
              }
            }
          }
          popupCounter++
        }
      })
    }
  }

  const showPopup = () => {
    if (isBrowser) {
      $(".newsletter_weekend_spa_popup").show()
    }
  }

  const applyWelcomeDiscount = () => {
    if (discountCode) {
      if (process.env.GATSBY_ACTIVE_ENV === "gbp") {
        addDiscount(checkout.id, discountCode)
      }
      // if (process.env.GATSBY_ACTIVE_ENV === "eur" && intl.locale === "fr") {
      //   addDiscount(checkout.id, discountCode)
      // }
      setInfoMessage(
        <FormattedMessage
          id="welcome_discount_applied"
          values={{
            amount: (
              <FormattedNumber
                value={discountAmount}
                key="discountAmount"
                style="currency"
                currency={process.env.GATSBY_CURRENCY_TYPE}
                currencyDisplay="narrowSymbol"
                maximumFractionDigits={0}
              />
            ),
            strong: (...chunks) => <strong>{chunks}</strong>,
            p: (...chunks) => <p>{chunks}</p>,
          }}
        />
      )
    }
  }

  useEffect(() => {
    isBrowser && windowResize()
    isBrowser && ShowPopupOnScroll()
    // isBrowser && handleTimeOut()
  })

  useEffect(() => {
    Translation()
  }, [intl.locale])

  return (
    <Fragment>
      {isBrowser &&
        showGiftIcon &&
        localStorage.getItem("newsLetterWeekendSpaPopup") != "true" && (
          <OpenNewsletterPopup onClick={() => showPopup()}>
            <GiftImage
              className="lazyload"
              data-src="https://static.percko.com/uploads/fb5f6d52-633d-493c-9d9a-7b3bd6474b9e.png"
            />
          </OpenNewsletterPopup>
        )}
      <NewsletterBoostPopup className="newsletter_weekend_spa_popup">
        <PopupWrapper>
          <BgImage>
            <Image
              src={
                secondStepComplete
                  ? "https://static.percko.com/uploads/adaa949c-7711-4e29-8686-21d5ee84c166.jpg"
                  : firstStepComplete && !secondStepComplete
                  ? "https://static.percko.com/uploads/f4cfbad2-f92b-456b-b95b-7a5e3d93742a.jpg"
                  : "https://static.percko.com/uploads/3ad6a8a3-c936-411a-924c-834b58357239.jpg"
              }
            />
          </BgImage>
          <InnerDiv>
            <NewsletterBoostPopupClose onClick={() => popupClose()}>
              ×
            </NewsletterBoostPopupClose>

            <Form onSubmit={handleSubmit(onSubmit)} id="newsletterPopupForm">
              <FirstStep show={!firstStepComplete}>
                <NewsletterBoostPopupTopBox>
                  <NewsletterTitle>Get £5 off</NewsletterTitle>
                </NewsletterBoostPopupTopBox>
                <NewsletterText
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "footer_newsletter_subscribe_message",
                    }),
                  }}
                />
                <NewsletterBoostPopupMiddle>
                  <FormRow className="form-group">
                    <PopupEmailInput
                      name="email"
                      id="email"
                      ref={register({
                        required: true,
                        pattern: emailRegex,
                      })}
                      aria-invalid={false}
                      onFocus={e => onFocus(e)}
                      onBlur={e => onBlur(e)}
                      onChange={checkUserEmail}
                    />
                    <PopupLabel htmlFor="email">Email</PopupLabel>
                    {errors && errors.email && (
                      <ErrorSpan>
                        {errors.email.type === "pattern"
                          ? NewsTrans.email_error_text
                          : NewsTrans.require_error_text}
                      </ErrorSpan>
                    )}
                    {error && (
                      <Error
                        dangerouslySetInnerHTML={{
                          __html: error,
                        }}
                      />
                    )}
                    <ButtonWrapper>
                      <SecondStepBtn onClick={() => moveSecondStep()}>
                        {intl.locale === "fr"
                          ? "Je m’inscris à la newsletter"
                          : "Subscribe"}
                      </SecondStepBtn>
                    </ButtonWrapper>
                  </FormRow>
                  {/* <FormRow>
                    <PopupEmailInput
                      name="dob"
                      id="dob"
                      ref={register({
                        required: false,
                        pattern: dobRe,
                      })}
                      aria-invalid={false}
                      onFocus={e => onFocus(e)}
                      onBlur={e => onBlur(e)}
                      onChange={checkAndFormatDob}
                    />
                    <PopupLabel htmlFor="dob">
                      Date de naissance (Optionnel)
                    </PopupLabel>
                    <Submit type="submit">S'inscrire</Submit>
                  </FormRow>
                  {errors && errors.dob && (
                    <ErrorSpan>
                      {errors.dob.type === "pattern"
                        ? NewsTrans.invalid_dob_text
                        : NewsTrans.invalid_dob_text}
                    </ErrorSpan>
                  )} */}
                  {success && (
                    <Success
                      dangerouslySetInnerHTML={{
                        __html: success,
                      }}
                    />
                  )}
                  <NewsletterBoostPopupBottom>
                    <FormattedMessage
                      id="newsletter_terms_and_conditions_message1"
                      values={{
                        gdpr_link: (
                          <a
                            href={intl.formatMessage({ id: "gdpr_link.url" })}
                            target="_blank"
                          >
                            {intl.formatMessage({
                              id: "gdpr_link.description",
                            })}
                          </a>
                        ),
                        terms_and_condition_link: (
                          <a
                            href={intl.formatMessage({
                              id: "terms_and_condition_link.url",
                            })}
                            target="_blank"
                          >
                            {intl.formatMessage({
                              id: "terms_and_condition_link.description",
                            })}
                          </a>
                        ),
                      }}
                    />
                  </NewsletterBoostPopupBottom>
                </NewsletterBoostPopupMiddle>
              </FirstStep>
              <SecondStep show={firstStepComplete && !secondStepComplete}>
                <NewsletterBoostPopupTopBox>
                  <NewsletterTitle>Exclusive access?</NewsletterTitle>
                </NewsletterBoostPopupTopBox>
                <NewsletterText
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id:
                        "Get exclusive access to deals* when you sign up for texts.",
                    }),
                  }}
                />
                <NewsletterBoostPopupMiddle>
                  <FormRow className="form-group">
                    <PopupEmailInput
                      name="telephone"
                      id="telephone"
                      ref={register({
                        pattern: /^((\+44)|(0)) ?\d{4} ?\d{6}$/,
                        min: 999,
                        max: 9999999999999,
                      })}
                      aria-invalid={false}
                      onFocus={e => onFocus(e)}
                      onBlur={e => onBlur(e)}
                    />
                    <PopupLabel htmlFor="telephone">
                      Mobile phone number (+441234567890)
                    </PopupLabel>
                    {errors && errors.telephone && (
                      <ErrorSpan>
                        {errors.telephone.type === "pattern"
                          ? NewsTrans.require_error_text
                          : NewsTrans.require_error_text}
                      </ErrorSpan>
                    )}
                    {error && (
                      <Error
                        dangerouslySetInnerHTML={{
                          __html: error,
                        }}
                      />
                    )}
                    <ButtonWrapper>
                      <Submit type="submit">
                        {intl.locale === "fr"
                          ? "Je m’inscris à la newsletter"
                          : "Sign-up"}
                      </Submit>
                    </ButtonWrapper>
                  </FormRow>
                  {success && (
                    <Success
                      dangerouslySetInnerHTML={{
                        __html: success,
                      }}
                    />
                  )}
                  <NewsletterBoostPopupBottom>
                    <FormattedMessage
                      id="newsletter_terms_and_conditions_message2"
                      values={{
                        gdpr_link: (
                          <a
                            href={intl.formatMessage({ id: "gdpr_link.url" })}
                            target="_blank"
                          >
                            {intl.formatMessage({
                              id: "gdpr_link.description",
                            })}
                          </a>
                        ),
                        terms_and_condition_link1: (
                          <a
                            href={intl.formatMessage({
                              id: "terms_and_condition_link1.url",
                            })}
                            target="_blank"
                          >
                            {intl.formatMessage({
                              id: "terms_and_condition_link1.description",
                            })}
                          </a>
                        ),
                      }}
                    />
                  </NewsletterBoostPopupBottom>
                </NewsletterBoostPopupMiddle>
              </SecondStep>
            </Form>
            <ThirdStep show={secondStepComplete}>
              <NewsletterBoostPopupTopBox>
                <NewsletterTitle>We've got your back</NewsletterTitle>
              </NewsletterBoostPopupTopBox>
              <ThankYouText>{infoMessage}</ThankYouText>
            </ThirdStep>
          </InnerDiv>
        </PopupWrapper>
        <LoaderBox
          style={{
            display: loading ? "flex" : "none",
          }}
        >
          <Loader
            type="Oval"
            color="#808080"
            height={30}
            width={30}
            secondaryColor={"#696969"}
          />
        </LoaderBox>
      </NewsletterBoostPopup>
    </Fragment>
  )
}

const NewsletterBoostPopup = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  z-index: 5;
  top: 0%;
  left: 0%;
`
const PopupWrapper = styled.div`
  max-width: 1040px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 110px;
  transform: translate(-50%, 0px);
  pointer-events: auto;
  display: flex;
  box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  background-color: #fff;

  @media (max-width: 767px) {
    width: 90%;
    max-width: 100%;
  }
`

const BgImage = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  @media (max-width: 767px) {
    display: none;
  }
`

const InnerDiv = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  padding: 25px 30px;
  flex-direction: column;
  justify-content: center;
  background: #d7edf6;
  min-height: 360px;

  @media (max-width: 767px) {
    height: auto;
    min-height: 350px;
  }
`

const LoaderBox = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100%;
  position: absolute;
`

const NewsletterBoostPopupClose = styled.div`
  position: absolute;
  right: 5px;
  line-height: 15px;
  color: #262626;
  top: 5px;
  font-size: 30px;
  cursor: pointer;
`

const FirstStep = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  justify-content: center;
`

const SecondStep = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  justify-content: center;
`

const ThirdStep = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: cover;
`

const NewsletterBoostPopupTopBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 20px;
`

const NewsletterTitle = styled.div`
  font-size: 40px;
  line-height: 1.2;
  text-transform: uppercase;
  font-family: "BebasNeueBold";
  font-weight: bold;
  text-align: center;
  background: #fff;
  padding: 8px 20px;
  border-radius: 8px;

  @media (max-width: 600px) {
    font-size: 36px;
  }
`

const NewsletterText = styled.div`
  font-family: "Gotham Book";
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  color: #262626;
  margin: 0px 0px 15px;
  text-align: center;

  p {
    margin: 0px 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    margin: 0px 10px 0px 0px;
    max-height: 15px;
  }
`

const ThankYouText = styled.div`
  font-family: "Gotham Book";
  font-weight: bold;
  color: #262626;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;

  p {
    margin: 0px 0px 10px;
  }
`

const NewsletterBoostPopupMiddle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
`

const NewsletterBoostPopupBottom = styled.div`
  width: 100%;
  color: #262626;
  font-size: 12px;
  font-weight: bold;
  font-family: "GothamLight";
  text-align: center;
  max-width: 230px;

  a {
    text-decoration: underline;
  }

  a:hover {
    color: #262626;
    text-decoration: underline;
  }

  p {
    margin: 0px;
  }
`

const Form = styled.form`
  display: flex;
  width: 100%;
  margin: 0px;
  flex-direction: column;

  input:-internal-autofill-selected {
    background-color: #f0f !important;
  }

  .focused + label {
    transform: translateY(-110%);
    font-size: 0.75em;
    @media (max-width: 380px) {
      font-size: 0.7em;
    }
  }
`

const FormRow = styled.div`
  width: 100%;
  position: relative;
  flex-direction: column;
  display: flex;
`

const PopupLabel = styled.label`
  position: absolute;
  top: 24px;
  left: 15px;
  transform: translateY(-50%);
  cursor: text;
  transition: transform 150ms ease-out, font-size 150ms ease-out;

  @media (max-width: 600px) {
    font-size: 13px;
  }

  @media (max-width: 400px) {
    font-size: 11px;
  }
`

const PopupEmailInput = styled.input`
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  margin: 0px;
  font-family: "GothamLight";
  color: #0e1116;
  font-weight: bold;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  box-shadow: none;
  font-size: 12px;
  color: #0e1116;
  ::placeholder {
    color: #0e1116;
  }
  :focus {
    background-color: #fff;
  }
  :-webkit-autofill {
    background: transparent;
  }
`

const ErrorSpan = styled.span`
  color: #b20000;
  font-size: 1em;
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 20px 0px;
  text-align: center;
`

const SecondStepBtn = styled.div`
  background: #262626;
  padding: 12px 20px;
  color: #fff;
  font-family: "Museo";
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  min-width: 220px;
  text-align: center;
  position: relative;
  cursor: pointer;
  border: 0px;
  width: 100%;

  :hover {
    color: #fff;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const Submit = styled.button`
  background: #262626;
  padding: 12px 20px;
  color: #fff;
  font-family: "Museo";
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  min-width: 220px;
  text-align: center;
  position: relative;
  width: 100%;
  cursor: pointer;
  border: 0px;

  :hover {
    color: #fff;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const Error = styled.div`
  color: #b20000;
  font-size: 14px;
  line-height: 20px;
  margin: 0.3em 0px 0px;
  width: 100%;
`

const Success = styled.div`
  font-family: "Gotham Book";
  font-weight: bold;
  color: #262626;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
`

const OpenNewsletterPopup = styled.a`
  position: fixed;
  bottom: 45%;
  right: 20px;
  cursor: pointer;
  width: 40px;
  z-index: 3;
  display: none;

  @media (max-width: 600px) {
    display: block;
    bottom: 65px;
  }
`

const GiftImage = styled.img`
  width: 100%;
  max-width: 40px;
  margin: 0px;
`

export default injectIntl(withStoreContext(NewsLetterMultipleStepPopup))
